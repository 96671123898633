<template>
  <router-link :to="`/orders/${item.id}`" class="block hover:bg-gray-50 dark:hover:bg-gray-770 w-full">
    <div class="py-4">
      <div class="sm:flex flex-wrap sm:flex-nowrap items-center">

        <div class="grid grid-cols-12 w-full">

          <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
            <item-text title="#" :text="item.uuid" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
            <item-text title="Datums" :text="item.order_date" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
            <item-text title="Summa EUR" :text="item.total" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
            <item-text title="PVN EUR" :text="item.vat" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
            <item-text title="Kopā EUR" :text="item.total_with_vat" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
            <item-text title="Statuss">
              <template v-slot:content>
                <Badge :text="item.status.name" :className="`${statusColor(item.status)} mr-2 mb-2 md:mb-0`" />
              </template>
            </item-text>
          </div>

        </div>

        <div class="ml-auto">
          <p class="mt-2 flex items-center text-sm font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
            <button class="text-gray-400 flex items-center whitespace-nowrap">
              Skatīt pasūtījumu
              <ChevronRightIcon class="w-6 h-6"/>
            </button>
          </p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import ItemText from "@/components/Components/ItemText"
import Colors from "@/services/Helpers/colors";
import Badge from "@/components/Components/Badge"

import {
  ChevronRightIcon,
} from "@heroicons/vue/solid";

export default {
  name: "SingleOrderHistoryEntry",
  components: {
    ItemText,
    Badge,
    ChevronRightIcon,
  },
  props: ['item'],
  methods: {
    statusColor(status) {
      let key = status ? status.id : 0
      return Colors.statusColor(key)
    },
    openOrder() {
      this.$router.push('/orders/' + this.item.id)
    },
  }
}
</script>

<style>
</style>