<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Pasūtījumu vēsture
      </h3>
    </template>
    <template v-slot:buttons>

      <!-- Show details -->
      <template v-if="data.length > 3">
        <Button :icon="showFullList ? 'chevron-up' : 'chevron-down'" @click="showFullList = !showFullList" />
      </template>

    </template>
    <template v-slot:content>

      <template v-if="orderHistory.length > 0">
        <template v-for="(item, index) in orderHistory" :key="index">
          <single-order-history-entry :item="item"/>
        </template>
      </template>

    </template>

  </item-card>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import SingleOrderHistoryEntry from "./SingleOrderHistoryEntry";

export default {
  name: "AllOrderHistory",
  components: {
    ItemCard,
      SingleOrderHistoryEntry
  },
  props: ['data'],
  data(){
    return {
      showFullList: false,
    }
  },
  computed:{
    orderHistory(){
      return this.showFullList ? this.data : this.data.slice(0, 3)
    }
  }
}
</script>

<style>

</style>